<template>
  <div>
    <button @click="startRecording" :disabled="isRecording">Start Recording</button>
    <button @click="stopRecording" :disabled="!isRecording">Stop Recording</button>

    <audio v-if="audioBlobUrl" :src="audioBlobUrl" controls></audio>

    <button @click="sendAudioWithFetch" :disabled="!audioBlobUrl">Send Audio</button>

    <ul>
      <li v-for="(transcript, index) in transcriptions" :key="index">{{ transcript }}</li>
    </ul>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const isRecording = ref(false)
const audioBlob = ref(null)
const audioBlobUrl = ref(null)
const transcriptions = ref([])

let mediaRecorder = null
let audioChunks = []
let audioContext = null
let audioBuffer = null

// Start recording audio
const startRecording = async () => {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
  mediaRecorder = new MediaRecorder(stream)
  audioContext = new (window.AudioContext || window.webkitAudioContext)()

  mediaRecorder.start()
  isRecording.value = true
  audioChunks = [] // Clear previous recordings

  // Capture audio chunks when available
  mediaRecorder.ondataavailable = (event) => {
    audioChunks.push(event.data)
  }
}

// Stop recording audio
const stopRecording = async () => {
  if (mediaRecorder) {
    mediaRecorder.stop()
    isRecording.value = false

    mediaRecorder.onstop = async () => {
      // Create a Blob from the recorded audio chunks
      const audioBlobRaw = new Blob(audioChunks, { type: 'audio/wav' })
      const arrayBuffer = await audioBlobRaw.arrayBuffer()
      
      // Decode the audio data into an AudioBuffer
      audioBuffer = await audioContext.decodeAudioData(arrayBuffer)

      // Convert the audio buffer to LINEAR16
      const linear16ArrayBuffer = convertToLinear16(audioBuffer)

      // Create a Blob from the LINEAR16 data
      audioBlob.value = new Blob([linear16ArrayBuffer], { type: 'audio/l16' })
      audioBlobUrl.value = URL.createObjectURL(audioBlob.value)
    }
  }
}

// Function to convert AudioBuffer to LINEAR16
const convertToLinear16 = (audioBuffer) => {
  const channelData = audioBuffer.getChannelData(0) // Get data for first channel only
  const bufferLength = audioBuffer.length
  const sampleRate = audioBuffer.sampleRate
  const linear16ArrayBuffer = new ArrayBuffer(bufferLength * 2) // 2 bytes per sample for LINEAR16
  const view = new DataView(linear16ArrayBuffer)

  for (let i = 0; i < bufferLength; i++) {
    const sample = Math.max(-1, Math.min(1, channelData[i])) // Clamp sample value
    view.setInt16(i * 2, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true) // Little-endian
  }

  console.log(`Converted to LINEAR16 with sample rate: ${sampleRate}`)
  return linear16ArrayBuffer
}

// Send the recorded LINEAR16 audio to the backend
const sendAudioWithFetch = async () => {
  if (!audioBlob.value) {
    alert('No audio to send')
    return
  }

  const formData = new FormData()
  formData.append("config", JSON.stringify({
    encoding: "LINEAR16",
    sampleRateHertz: audioBuffer.sampleRate, // Use the sample rate of the audioBuffer
    languageCode: "en-US",
    enableWordTimeOffsets: true
  }))
  formData.append("audio", audioBlob.value, "audio.l16")

  const requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow"
  }

  try {
    const response = await fetch(`${process.env.VUE_APP_SOFA_API}stream`, requestOptions)

    if (!response.ok) {
      const errorText = await response.text()
      console.error(`Error: ${response.status} - ${errorText}`)
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const reader = response.body.getReader()
    const decoder = new TextDecoder('utf-8')

    const processChunk = async () => {
      const { done, value } = await reader.read()
      if (done) return

      const chunk = decoder.decode(value, { stream: true })
      console.log('Received chunk:', chunk)

      chunk.split('\n').forEach(line => {
        if (line.trim()) {
          try {
            const parsedResponse = JSON.parse(line)
            if (parsedResponse.transcript) {
              transcriptions.value.push(parsedResponse.transcript)
            }
          } catch (error) {
            console.error('Error parsing chunk:', error)
          }
        }
      })

      processChunk() // Continue processing the next chunk
    }

    processChunk()

  } catch (error) {
    console.error('Error streaming audio:', error)
    alert('Error streaming audio. Please check the console for details.')
  }
}
</script>
