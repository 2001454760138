// src/utils/useWebSocket.js
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { useToast } from 'vue-toastification';
const toast = useToast();

export function useWebSocket(wsUrl, handleMessage) {
  const socket = ref(null);
  const isConnected = ref(false);

  const connect = () => {
    if (socket.value && socket.value.readyState === WebSocket.OPEN) return; 
    socket.value = new WebSocket(wsUrl);

    socket.value.addEventListener('open', () => {
      console.log('WebSocket connection established.');
      isConnected.value = true;
    });

    socket.value.addEventListener('message', (event) => {
      try {
        const receivedData = JSON.parse(event.data);
        console.log('Received message:', receivedData);
        if (handleMessage) {
          handleMessage(receivedData); 
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
        toast.error('Error parsing WebSocket message:', error);

      }
    });

    socket.value.addEventListener('close', (event) => {
      console.log(`WebSocket connection closed: Code ${event.code}, Reason: ${event.reason || 'No reason provided'}`);
      isConnected.value = false;
    });

    socket.value.addEventListener('error', (error) => {
      console.error('WebSocket error:', error);
      // toast.error('WebSocket error:', error);

    });
  };

  const sendMessage = (message) => {
    if (socket.value && socket.value.readyState === WebSocket.OPEN) {
      socket.value.send(JSON.stringify(message));
      console.log('Message sent to WebSocket:', message);
    } else {
      console.warn('WebSocket is not open. Unable to send message.');
      toast.warning('WebSocket is not open. Unable to send message.');

    }
  };

  const closeConnection = () => {
    if (socket.value && socket.value.readyState !== WebSocket.CLOSED) {
      socket.value.close();
    }
  };

  onMounted(() => {
    connect();
  });

  onUnmounted(() => {
    closeConnection();
  });

  watch(wsUrl, (newUrl, oldUrl) => {
    if (newUrl !== oldUrl) {
      closeConnection();
      wsUrl = newUrl;
      connect();
    }
  });

  return {
    socket,
    isConnected,
    sendMessage,
    closeConnection,
  };
}
