<template>
  <div class="container d-flex justify-content-center align-items-center vh-100">
    <div class="card p-4 shadow-lg form-wrapper">
      <img src="./../../assets/logo_sofia.png" alt="Logo" class="img-fluid mx-auto d-block mb-3" />
      <form @submit.prevent="handleResetPassword" class="form">
        <div class="mb-3">
          <label for="password" class="form-label">New Password</label>
          <input
            v-model="password"
            type="password"
            id="password"
            class="form-control"
            placeholder="Enter new password"
            required
          />
        </div>
        <div class="mb-3">
          <label for="confirmPassword" class="form-label">Confirm Password</label>
          <input
            v-model="confirmPassword"
            type="password"
            id="confirmPassword"
            class="form-control"
            placeholder="Confirm new password"
            required
          />
        </div>
        <button
          type="submit"
          class="btn btn-primary w-100"
          :disabled="!password || !confirmPassword"
        >
          Reset Password
        </button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

const password = ref('');
const confirmPassword = ref('');
const router = useRouter();
const store = useStore();
const toast = useToast();

const handleResetPassword = async () => {
  if (password.value !== confirmPassword.value) {
    console.error = "Passwords do not match.";
    toast.error("Passwords do not match.");
    return;
  }

  const token = store.getters.token;

  const resetPasswordServices = async (password, token) => {
    try {
      const result = await axios.post(
        `${process.env.VUE_APP_SOFA_API}reset_password`,
        {
          password,
          token,
        },
        {
          headers: {
            "x-access-tokens": token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      if (result.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      if (e.response) {
        const statusCode = e.response.status;
        console.error = `Error code: ${statusCode}`;
        toast.error(`Error code: ${statusCode}`);
      } else {
        console.error = 'An unexpected error occurred';
        toast.error('An unexpected error occurred');
      }
      return false;
    }
  };

  const success = await resetPasswordServices(password.value, token);
  if (success) {
    console.error = 'Password reset successfully!';
    toast.success('Password reset successfully!');
    router.push('/login');
  } else {
    console.error = 'Failed to reset password. Please try again.';
    toast.error('Failed to reset password. Please try again.');
  }
};
</script>

<style scoped>

.form-wrapper {
  max-width: 400px;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-control {
  padding: 0.8em;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.btn:disabled {
  opacity: 0.65;
}
</style>
