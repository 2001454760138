<template>
  <div class="container d-flex justify-content-center align-items-center vh-100">
    <div class="card p-4 shadow-lg forget-password">
      <img src="./../../assets/logo_sofia.png" alt="Logo" class="img-fluid mx-auto d-block mb-3">
      <h3 class="text-center mb-4">Forgot Password</h3>
      <form @submit.prevent="forgetPassword">
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input
            type="email"
            id="email"
            class="form-control"
            v-model="email"
            required
            placeholder="Enter your email"
          />
        </div>
        <button :disabled="!email" type="submit" class="btn btn-primary w-100">
          Send
        </button>
        <p class="text-center mt-3">
          Back to <a href="/login">Login</a>
        </p>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

const baseUrl = process.env.VUE_APP_SOFA_API;
const router = useRouter();
const email = ref('');
const store = useStore();
const toast = useToast();

const forgetPassword = async () => {
  try {
    const response = await axios.post(
      `${baseUrl}verify`,
      {},
      {
        headers: {
          'Authorization': email.value,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    const statusCode = response.status;
    if (statusCode === 200) {
      store.commit('setPhone', response.data.responseData.phone);
      toast.success("Password reset link sent successfully.");
      router.push({ name: 'OtpPage', query: { from: 'forget-password' } });
    } else {
      console.error = 'Error sending password reset link.';
      toast.error("Error sending password reset link.");

    }
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    if (statusCode) {
      console.error = `An error occurred: ${statusCode}. Please try again later.`;
      toast.error(`An error occurred: ${statusCode}. Please try again later.`);
    } else {
      console.error = 'An unknown error occurred. Please try again later.';
      toast.error('An unknown error occurred. Please try again later.');

    }
  }
};
</script>

<style scoped>
.forget-password {
  max-width: 400px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
}

img {
  width: 150px;
}

.card {
  border: none;
}

button:disabled {
  opacity: 0.65;
}

.text-danger {
  color: #e74c3c;
}
</style>
