import 'axios';
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export function setupJWTInterceptor() {
const router = useRouter();
const store = useStore();

const JWTinterceptor = axios.create({
  baseURL: process.env.VUE_APP_SOFA_API,
  
});

JWTinterceptor.interceptors.request.use(
  (config) =>{
    const token = localStorage.getItem('token');
    if (token){
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['x-access-tokens'] = token;
    }
    return config;
  },
  (error)=>{
    return Promise.reject(error);
  }
);

JWTinterceptor.interceptors.response.use(
  (response) =>{
    return response
  },
  // (error) =>{
  //   if(error.response && error.response.status === 401){
  //     localStorage.removeItem('token');
  //     window.location.href('/login')
  //   }
  //   return Promise.reject(error)
  // }
  async (error) => {
    const originalRequest = error.config;

    // If token has expired, handle 401 response
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      console.log("token")
      
      // Optional: Refresh the token here if you have a refresh-token endpoint
      const refreshToken = localStorage.getItem('refreshToken');
      console.log(refreshToken)

      if (refreshToken) {
        try {
          // Call your API to refresh the token (this is a placeholder)
          const { data } = await axios.post(`${process.env.VUE_APP_SOFA_API}refresh`,
            { refresh_token: refreshToken });

            if( data.responseCode === 200 && data.responseData.token){
              const newToken = data.responseData.token;
              console.log(newToken)
              localStorage.setItem('token', newToken);
              originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
              originalRequest.headers['x-access-tokens'] = newToken;
            return JWTinterceptor(originalRequest);
            }

        } catch (refreshError) {
          // Handle failed token refresh (logout or redirect)
          console.log('Refresh token failed:', refreshError);
          //  localStorage.removeItem('token');
          // localStorage.removeItem('refreshToken');
          // window.location.href = '/login';
          // const logout = async () => {
    
        await store.dispatch('auth/logout');
        router.push('/login'); 
    
        }
      }
      else{
        // window.location.href = '/login';
        router.push('/login')
      }
    }
    
  }
  

)

return JWTinterceptor;
}

export default setupJWTInterceptor;