<template>
  <div class="register container mt-5">
    <div class="row justify-content-center">
      <div >
        <img src="./../../assets/logo_sofia.png" alt="Logo" class="img-fluid mb-4">
        <form @submit.prevent="handleRegister" class="row">
          <div class="mb-3 col-md-6">
            <label for="firstName" class="form-label">First Name</label>
            <input type="text" id="firstName" v-model="firstName" class="form-control" required />
          </div>
          <div class="mb-3 col-md-6">
            <label for="lastName" class="form-label">Last Name</label>
            <input type="text" id="lastName" v-model="lastName" class="form-control" required />
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" id="email" v-model="email" class="form-control" required />
          </div>
          <div class="mb-3">
            <label for="phone" class="form-label">Phone Number</label>
            <input type="tel" id="phone" v-model="phone" class="form-control" required />
          </div>
          <div class="mb-3 col-md-6">
            <label for="lic" class="form-label">LIC</label>
            <input type="text" id="lic" v-model="lic" class="form-control" required />
          </div>
          <div class="mb-3 col-md-6">
            <label for="npi" class="form-label">NPI</label>
            <input type="text" id="npi" v-model="npi" class="form-control" required />
          </div>
          <div class="mb-3">
            <label for="state" class="form-label">State</label>
            <select v-model="selectedState" id="state" class="form-select" required>
              <option value="" disabled>Select a state</option>
              <option v-for="state in states" :key="state.name" :value="state.name">
                {{ state.name }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input type="password" id="password" v-model="password" class="form-control" required />
          </div>
          <div class="mb-3">
            <label for="clinicCode" class="form-label">Clinic Code</label>
            <input type="text" id="clinicCode" v-model="clinicCode" class="form-control" required />
          </div>
          <div class="mb-3">
            <label for="specialty" class="form-label">Select your specialty</label>
            <select v-model="selectedSpecialty" id="specialty" class="form-select" required>
              <option value="" disabled>Select a specialty</option>
              <option v-for="specialty in specialties" :key="specialty.id" :value="specialty.id">
                {{ specialty.name }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <a href="https://ensofia.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
              By registering, you agree to our terms and conditions (Here)
            </a>
          </div>
          <button type="submit" class="btn btn-primary w-100" :disabled="!isFormValid || loading">Register</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import states from '@/assets/states.json';

const firstName = ref('');
const lastName = ref('');
const email = ref('');
const password = ref('');
const phone = ref('');
const lic = ref('');
const npi = ref('');
const clinicCode = ref('');
const selectedSpecialty = ref('');
const specialties = ref([]);
const selectedState = ref('');
// const states = ref([]);
const loading = ref(false);
const toast = useToast();
const router = useRouter();
const store = useStore()

const fetchSpecialties = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_SOFA_API}getSpecialtyList`);
    specialties.value = response.data;
  } catch (error) {
      toast.error('Error fetching specialties:', error);
      console.error('Error fetching specialties:', error);
  }
};

onMounted(() => {
  fetchSpecialties();
});

const isFormValid = computed(() => {
  return firstName.value && lastName.value && email.value && password.value && phone.value && lic.value && npi.value && selectedState.value && clinicCode.value && selectedSpecialty.value;
});

const handleRegister = async () => {
  loading.value = true;
  try {
    const response = await axios.post(`${process.env.VUE_APP_SOFA_API}register`, {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      password: password.value,
      phone: phone.value,
      lic: lic.value,
      npl: npi.value,
      clinic_code: clinicCode.value,
      specialty_id: selectedSpecialty.value,
      state: selectedSpecialty.value,
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    console.log(response.data);
    store.commit('setPhone', response.data.responseData.phone);
    router.push('/otp');
  } catch (error) {
    const statusCode = error.response?.status;
    if (statusCode === 409) {
      error.value("The account already exists");
      toast.error("The account already exists");
    } else if (statusCode === 201 ||statusCode === 200) {
      console.log("User created successfully");
      toast.success("User created successfully");
    } else {
      error.value(`Server error ${statusCode}`);
      toast.error(`Server error ${statusCode}`);
    }
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.register {
  max-width: 400px;
  margin: 0 auto;
  background: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}
</style>
