<template>
  <div class="container d-flex justify-content-center align-items-center min-vh-100">
    <div class="card p-4" style="max-width: 400px; width: 100%;">
      <div class="text-center mb-4">
        <img src="./../../assets/logo_sofia.png" alt="Logo" class="img-fluid">
      </div>
      <form @submit.prevent="login">
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input 
            v-model="email" 
            type="email" 
            id="email" 
            class="form-control" 
            placeholder="Enter your email" 
            required
            @input="checkForm"
          />
        </div>

        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input 
            v-model="password" 
            type="password" 
            id="password" 
            class="form-control" 
            placeholder="Enter your password" 
            required
            @input="checkForm"
          />
        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <a href="/forget-password" class="link-primary">Forget your password?</a>
          <button type="submit" class="btn btn-primary" :disabled="!isFormValid || loading">Login</button>
        </div>
        
        <p class="text-center">Don't have an account? <a href="/register" class="link-primary">Signup</a></p>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

const email = ref('');
const password = ref('');
const isFormValid = ref(false);
const error = ref('');
const router = useRouter();
const store = useStore();
const loading = ref(false);
const toast = useToast();

const checkForm = () => {
  isFormValid.value = email.value !== '' && password.value !== '';
};

const login = async () => {
  loading.value = true;
  error.value = '';

  try {
    await store.dispatch('auth/login', {
      email: email.value,
      password: password.value,
    });
    router.push('/');
  } catch (err) {
    if (err.response) {
      const statusCode = err.response.status;
      const message = err.response.data.message;
      if (statusCode === 401 && message == "user exists but not active") {
        
        router.push('/otp');
      } else if (statusCode === 400) {
        error.value = 'Please try again later.';
        toast.error("Please try again later.");
      } else if (statusCode === 401) {
        error.value = 'The password or email is incorrect.';
        toast.error("The password or email is incorrect.");
      } else {
        error.value = `Server error ${statusCode}`;
        toast.error(`Server error ${statusCode}`);

      }
    } else {
      error.value = 'Please try again later.';
    }
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.card {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.form-control {
  border-radius: 4px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.link-primary {
  color: #007bff;
}

.link-primary:hover {
  color: #0056b3;
}
</style>
