<template>
    <div class=" d-flex flex-column vh-100" style="width: 17%;" :class="{'sidebar-collapsed': isCollapsed}">
        <div class="d-flex justify-content-around nav-header">
            <a href="/"><img  class="logo" src="./../assets/logo_sofia.png" alt="" ></a>
            <div class="p-3 text-white toggle-btn d-block d-md-none" @click="toggleSidebar">
                <i class="fas fa-times"></i>
            </div>
        </div>
        <div class="nav-upper flex-grow-1 overflow-auto">
            <EncountersList  />
        </div>
        <div class="nav-lower p-3">
            <ul class="nav flex-column">
                <li class="nav-item" v-for="item in lowerNavItems" :key="item.name">
                    <a v-if="item.path.startsWith('http')" :href="item.path" target="_blank" rel="noopener noreferrer" class="nav-link text-dark">
                        <i :class="item.icon"></i> <span v-if="!isCollapsed"> {{ item.name }}</span>
                        </a>
                    <router-link  v-else class="nav-link text-dark" :to="item.path">
                    <i :class="item.icon"></i>
                    <span v-if="!isCollapsed">{{ item.name }}</span>
                    </router-link>
                </li>
                <li class="nav-link text-dark" @click="logout">
                    <i class="fa-solid fa-arrow-right-from-bracket"></i>
                    <span> Logout</span>
                </li>
                <small class="nav-link text-dark">V 0.0.2</small>
                <li></li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import EncountersList from "@/pages/encountersList.vue";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
const router = useRouter();
const store = useStore();

const logout = async () => {
    try {
        await store.dispatch('auth/logout');
        router.push('/login'); 
    } catch (error) {
        console.error('Logout failed:', error);
    }
    };

    const isCollapsed = ref(false);
    const toggleSidebar = () => {  
        isCollapsed.value = !isCollapsed.value;
    };

    const lowerNavItems = [
        { name: 'Help', path: 'https://ensofia.com/', icon: 'fa-regular fa-circle-question' },
        { name: 'Contact', path: 'https://ensofia.com/contact-us/', icon: 'fa-solid fa-mobile-screen-button' },
    ];
</script>

<style scoped>
li{
    cursor:pointer;
}
.sidebar-collapsed {
    display: none;
}
.logo{
    width: 150px;
    height: 60px;
}
.nav-header{
    background-color: #1f86ae;
}
.toggle-btn {
    text-align: center;
    cursor: pointer;
}

.nav-link i {
    margin-right: 10px;
}
.sidebar-collapsed .nav-link span {
    display: none;
}
.nav-upper {
  max-height: calc(100vh - 150px); 
  overflow-y: scroll; 
  
  /* Firefox scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #1f86ae #f1f1f1;
}

/* Custom scrollbar styles for WebKit browsers */
.nav-upper::-webkit-scrollbar {
  width: 12px; 
}

.nav-upper::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.nav-upper::-webkit-scrollbar-thumb {
  background-color: #1f86ae; 
  border-radius: 10px; 
  border: 3px solid #f1f1f1; 
}

.nav-upper::-webkit-scrollbar-thumb:hover {
  background-color: #1f86ae; 
}

@media (max-width: 768px) {
    .sidebar-collapsed {
        display: none !important;
    }
    .nav-lower {
        flex-direction: row;
    }
    .nav-lower .nav-link {
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    }
}
</style>
